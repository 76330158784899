import { Box, Button, Drawer } from "@mui/material";
import NavSection from "./NavSection";
import Logo from "../common/logo2.svg";
import InfoIcon from "@mui/icons-material/Info";
import CallIcon from "@mui/icons-material/Call";
import ListIcon from "@mui/icons-material/List";
import CollectionsIcon from "@mui/icons-material/Collections";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import "../index.css";
import HomeIcon from "@mui/icons-material/Home";
import CloseIcon from "@mui/icons-material/Close";
import { red } from "@mui/material/colors";
import { useContext } from "react";
import { AppContext } from "../context/appContext.js";
import { LanguagePopover } from "./LanguagePopover.jsx";

const DRAWER_WIDTH = 280;

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { lan } = useContext(AppContext);

  const renderContent = (
    <>
      <Box
        sx={{
          width: "92%",
          textAlign: "left",
        }}
      >
        <img src={Logo} className="logostyleSidebar" alt={"Header"} />
      </Box>
      <NavSection
        navConfig={[
          {
            title: lan === 0 ? "Acasă" : "Home",
            path: "/",
            icon: <HomeIcon sx={{ color: "#0d0d3b" }} />,
          },
          {
            title: lan === 0 ? "Despre noi" : "About Us",
            path: "/about",
            icon: <InfoIcon sx={{ color: "#0d0d3b" }} />,
          },
          {
            title: lan === 0 ? "Servicii" : "Services",
            path: "/services",
            icon: <ListIcon sx={{ color: "#0d0d3b" }} />,
          },
          {
            title: "Contact",
            path: "/contact",
            icon: <CallIcon sx={{ color: "#0d0d3b" }} />,
          },
          {
            title: lan === 0 ? "Lucrări realizate" : "Our Projects",
            path: "/album",
            icon: <CollectionsIcon sx={{ color: "#0d0d3b" }} />,
          },
          {
            title: lan === 0 ? "Atestări" : "Certificates",
            path: "/documents",
            icon: <WorkspacePremiumIcon sx={{ color: "#0d0d3b" }} />,
          },
        ]}
      />
      <Box
        sx={{
          ml: 4.1,
          display: "flex",
        }}
      >
        <LanguagePopover fontColor={"#0d0d3b"} size={"15px"} inFooter={false} />
      </Box>
      <Box
        sx={{
          bottom: 30,
          position: "fixed",
          left: 90,
          backgroundColor: "#ffffff",
        }}
      >
        <Button sx={{ color: red[700] }} onClick={onCloseSidebar}>
          <CloseIcon /> {lan === 0 ? "Închide" : "Close"}
        </Button>
      </Box>
    </>
  );

  return (
    <Drawer
      open={isOpenSidebar}
      onClose={onCloseSidebar}
      PaperProps={{
        sx: { width: DRAWER_WIDTH },
      }}
    >
      {renderContent}
    </Drawer>
  );
}
