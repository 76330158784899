import { createContext, useEffect, useState } from "react";
import { useResponsive } from "../hooks/useResponsive.js";

export const AppContext = createContext();

export const AppProvider = ({children})=>{
    const [lan, setLan] = useState(0);

    const [desktop, setDesktop] = useState(false);
    const [tabletHorizontal, setTabletHorizontal] = useState(false);
    const [tabletVertical, setTabletVertical] = useState(false);
    const [phone, setPhone] =  useState(false);
    const [tabletBig, setTabletBig] = useState(false);

    const windowDimensions = useResponsive();

    useEffect(() => {
      if (
        (windowDimensions.width > windowDimensions.height) &&
        windowDimensions.height <= 900
      ){
        setTabletHorizontal(true);
        setDesktop(false);
        setPhone(false);
        setTabletBig(false);
        setTabletVertical(false);
      }
      else if (
       ( windowDimensions.width < windowDimensions.height) && windowDimensions.width<540
      ){
        setTabletHorizontal(false);
        setDesktop(false);
        setPhone(true);
        setTabletBig(false);
        setTabletVertical(false);
      }
      else if (windowDimensions.width < windowDimensions.height)
      {
        if(windowDimensions.width> 540 && windowDimensions.width<900 ){
          setTabletHorizontal(false);
          setDesktop(false);
          setPhone(false);
          setTabletBig(false);
          setTabletVertical(true);
        }
        if(windowDimensions.width>=900 && windowDimensions.height>=1200){
          setTabletHorizontal(false);
          setDesktop(false);
          setPhone(false);
          setTabletBig(true);
          setTabletVertical(false);
        }
      }
      else {
        setTabletHorizontal(false);
        setDesktop(true);
        setPhone(false);
        setTabletBig(false);
        setTabletVertical(false);
      }
    }, [windowDimensions]);


    function importAll(r) {
      let images = [];
      r.keys().forEach((item, i) => {
        images[i] = r(item);
      });
      return images;
    }

    const [images, setImages] = useState(importAll(
      require.context("../pages/album", false, /\.(png|jpe?g|svg|webp)$/)
    ));

    const [docs, setDocs] = useState(importAll(
      require.context("../pages/docs", false, /\.(png|jpe?g|svg)$/)
    ))
    
    useEffect(()=>{
      setImages(importAll(
        require.context("../pages/album", false, /\.(png|jpe?g|svg|webp)$/)
      ));
      setDocs(importAll(
        require.context("../pages/docs", false, /\.(png|jpe?g|svg)$/)
      ))
    },[])

    return (
        <AppContext.Provider value={{
            lan, 
            setLan,
            desktop,
            tabletHorizontal,
            tabletVertical,
            phone, 
            tabletBig,
            images,
            docs
        }}>
            {children}
        </AppContext.Provider> 
    )
}