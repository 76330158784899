import { Card, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { alpha, Container } from "@mui/system";
import { Presentation } from "../components/Presentation.jsx";
import "../index.css";
import { useContext, useState } from "react";
import { AppContext } from "../context/appContext.js";
import { Footer } from "../components/Footer.jsx";
import { Header } from "../components/Header.jsx";
import { useEffect } from "react";

const servicesRO = [
  `Proiectare și executare de instalații electrice interioare pentru construcții civile și industriale`,
  `Branșamente aeriene și subterane la tensiune nominală de 0,4 kV`,
  `Proiectare și executare de linii electrice, aeriene și subterane cu tensiuni nominale de 0,4 - 110 kV și posturi de transformare cu tensiunea nominală de cel mult 20 kV`,
  `Proiectare de linii electrice aeriene cu tensiuni nominale de 110 kV - 400 kV, linii electrice subterane cu tensiuni nominale de 110 kV sau 220 kV`,
  `Proiectare, extindere, modernizare și întreținere sisteme de iluminat public și arhitectural`,
];

const servicesEN = [
  `Design and execution of interior electrical installations for civil and industrial constructions`,
  `Overhead and underground connections at a nominal voltage of 0.4 kV`,
  `Design and execution of electrical, overhead and underground lines with nominal voltages of 0.4 - 110 kV and transformer substations with nominal voltages of no more than 20 kV`,
  `Design of overhead power lines with nominal voltages of 110 kV - 400 kV, underground power lines with nominal voltages of 110 kV or 220 kV`,
  `Design, expansion, modernization and maintenance of public and architectural lighting systems`,
];

export const Services = () => {
  const { lan, desktop, tabletBig, tabletVertical } = useContext(AppContext);

  const [top, setTop] = useState(22);
  const setMarginTop = () => {
    if (desktop) setTop(10);
    else if (tabletBig) setTop(27);
    else if (tabletVertical) setTop(14);
    else setTop(5);
  };

  useEffect(() => {
    setMarginTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, tabletVertical, tabletBig]);

  return (
    <section className="backsectionservices">
      <Header />
      <Container
        sx={{
          width: desktop ? "70%" : "95%",
          zIndex: 9,
          mt: desktop ? 22 : 7,
          mb: top,
        }}
      >
        <Grid container spacing={2} display="block">
          <Presentation
            title={
              lan === 0 ? "Ce servicii oferim?" : "What services do we offer?"
            }
            desc={
              lan === 0
                ? "Pentru mai multe informații ne puteți contacta prin numărul de telefon sau adresa de e-mail, afișate în secțiunea "
                : "For more details please contact us through the phone number or e-mail address, which are available within the following section: "
            }
            desc2={
              lan === 0
                ? " Prețurile serviciilor oferite de noi diferă în funcție de furnizori."
                : "The prices may differ depending on our suppliers."
            }
          />
          {lan === 0 &&
            servicesRO.map((service, i) => (
              <Grid key={i} item>
                <Card
                  sx={{
                    boxShadow: "none",
                    mt: 1,
                    pt: 2,
                    pl: 3,
                    pr: 3,
                    pb: 2,
                    backgroundColor: alpha("#0d0d3b", 0.1),
                    ":hover": {
                      backgroundColor: alpha(grey[100], 0.7),
                      cursor: "text",
                    },
                  }}
                >
                  <Typography
                    className="fontSize"
                    variant="body1"
                    color="#0d0d3b"
                  >
                    {service}
                  </Typography>
                </Card>
              </Grid>
            ))}
          {lan === 1 &&
            servicesEN.map((service, i) => (
              <Grid key={i} item>
                <Card
                  sx={{
                    boxShadow: "none",
                    mt: 1,
                    pt: 2,
                    pl: 3,
                    pr: 3,
                    pb: 2,
                    backgroundColor: alpha("#0d0d3b", 0.1),
                    ":hover": {
                      backgroundColor: alpha(grey[100], 0.7),
                      cursor: "text",
                    },
                  }}
                >
                  <Typography
                    className="fontSize"
                    variant="body1"
                    color="#0d0d3b"
                  >
                    {service}
                  </Typography>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </section>
  );
};
