import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { AppProvider } from './context/appContext.js';
import { About } from './pages/About.jsx';
import { Album } from './pages/Album.jsx';
import { Contact } from './pages/Contact.jsx';
import { Documents } from './pages/Documents.jsx';
import { Home } from './pages/Home.jsx';
import { NotFound } from './pages/NotFound.jsx';
import { Services } from './pages/Services.jsx';
import "react-image-lightbox/style.css";

function App() {
  return (
    <AppProvider>
      <Router>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/documents" element={<Documents/>} />
            <Route path="#" element={<NotFound/>} />
            <Route path="/album" element={<Album/>} />
            <Route path="/contact" element={<Contact/>} />
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;
