import { Box, Container, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CardItem } from "../components/CardItem.jsx";
import "../index.css";
import { Footer } from "../components/Footer.jsx";
import { Header } from "../components/Header.jsx";
import { AppContext } from "../context/appContext.js";
import Lightbox from "react-image-lightbox";
require.context("./docs", false, /\.(png|jpe?g|svg)$/);

export const Documents = () => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");

  const { desktop, tabletHorizontal, docs } = useContext(AppContext);

  const [imgIndex, setImgIndex] = useState(0);

  useEffect(() => {
    setImgIndex(docs.indexOf(image));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <section className="backsectiondocuments">
      <Header />
      <Container
        maxWidth={false}
        sx={{
          mb: 20,
          mt: desktop ? 40 : 5,
          width: desktop ? "85%" : "95%",
        }}
      >
        <Grid
          container
          display={desktop || tabletHorizontal ? "flex" : "block"}
          spacing={1}
        >
          <Box
            mb={3}
            mr={5}
            sx={{
              display: "flex",
              width: desktop || tabletHorizontal ? "30%" : "100%",
              justifyContent: "space-between",
            }}
          >
            <CardItem
              setOpen={setOpen}
              setImage={setImage}
              image={docs[1]}
              isDoc={true}
              card={docs[1]}
            />
            <Box mr={1}></Box>
            <CardItem
              setOpen={setOpen}
              setImage={setImage}
              image={docs[0]}
              isDoc={true}
              card={docs[0]}
            />
          </Box>

          <Box
            mb={3}
            mr={5}
            sx={{
              display: "flex",
              width: desktop || tabletHorizontal ? "30%" : "100%",
            }}
          >
            <CardItem
              setOpen={setOpen}
              setImage={setImage}
              image={docs[3]}
              isDoc={true}
              card={docs[3]}
            />
            <Box mr={1}></Box>
            <CardItem
              setOpen={setOpen}
              setImage={setImage}
              image={docs[2]}
              isDoc={true}
              card={docs[2]}
            />
          </Box>

          <Box
            mb={3}
            mr={5}
            sx={{
              display: "flex",
              width: desktop || tabletHorizontal ? "30%" : "100%",
            }}
          >
            <CardItem
              setOpen={setOpen}
              setImage={setImage}
              image={docs[5]}
              isDoc={true}
              card={docs[5]}
            />
            <Box mr={1}></Box>
            <CardItem
              setOpen={setOpen}
              setImage={setImage}
              image={docs[4]}
              isDoc={true}
              card={docs[4]}
            />
          </Box>
        </Grid>
        {open && (
          <Lightbox
            mainSrc={docs[imgIndex] ?? docs[0]}
            nextSrc={docs[(imgIndex + 1) % docs.length]}
            prevSrc={docs[(imgIndex + docs.length - 1) % docs.length]}
            onCloseRequest={() => setOpen(false)}
            onMovePrevRequest={() =>
              setImgIndex((imgIndex + docs.length - 1) % docs.length)
            }
            onMoveNextRequest={() => setImgIndex((imgIndex + 1) % docs.length)}
          />
        )}
      </Container>
      <Footer />
    </section>
  );
};
