import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { LanguagePopover } from "./LanguagePopover.jsx";
import { grey } from "@mui/material/colors";
import "../index.css";
import { useContext } from "react";
import headerImg from "../common/logo2.svg";
import { AppContext } from "../context/appContext.js";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

export const Footer = () => {
  const { lan, desktop, phone, tabletBig } = useContext(AppContext);
  const navigate = useNavigate();

  const [gridWidth, setGridWidth] = useState("20%");
  const setGridItemWidth = () => {
    if (phone || tabletBig) setGridWidth("50%");
    else if (desktop) setGridWidth("20%");
    else setGridWidth("25%");
  };

  useEffect(() => {
    setGridItemWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, phone]);

  return (
    <Box
      className="footersection"
      sx={{
        backgroundColor: grey[200],
      }}
    >
      <Box
        style={{
          marginLeft: desktop ? "3%" : "7%",
          paddingBottom: desktop ? "1%" : "5%",
          marginRight: desktop ? 0 : "5%",
        }}
      >
        <Grid
          container
          direction="row"
          columnSpacing={phone ? "10%" : "5%"}
          rowSpacing={"5%"}
        >
          {desktop && (
            <Grid item style={{ marginRight: "2%" }}>
              <Box onClick={() => navigate("/")}>
                <img
                  className="logoStyleFooter"
                  src={headerImg}
                  alt={"Header"}
                />
              </Box>
            </Grid>
          )}
          <Grid item width={gridWidth}>
            <Box>
              <Typography
                variant="h5"
                className={"footerHeader"}
                sx={{
                  fontFamily: "Anton",
                  color: "#0d0d3b",
                  mb: 2.5,
                }}
              >
                M.D. Electric
              </Typography>
              <Typography
                className="footerFontSize"
                style={{ color: grey[800] }}
              >
                {lan === 0
                  ? "Lucrări instalații electrice"
                  : "Electrical installations works"}
              </Typography>
              <Typography
                className="footerFontSize"
                style={{ color: grey[800] }}
              >
                {lan === 0
                  ? "Activități arhitecturale"
                  : "Architectural activities"}
              </Typography>
            </Box>
          </Grid>
          <Grid item width={gridWidth}>
            <Box className="block">
              <Typography
                className={"footerHeader"}
                variant="h5"
                sx={{ fontFamily: "Anton", mb: 2, color: "#0d0d3b" }}
              >
                {lan === 0 ? "Traducere" : "Translate"}
              </Typography>
              <LanguagePopover fontColor={grey[800]} inFooter={true} />
            </Box>
          </Grid>
          <Grid item width={gridWidth}>
            <Box>
              <Typography
                variant="h5"
                className={"footerHeader"}
                sx={{
                  fontFamily: "Anton",
                  color: "#0d0d3b",
                  mb: 2.8,
                }}
              >
                {lan === 0 ? "Secțiuni" : "Quick Links"}
              </Typography>
              <Box display="flex">
                <Box display="block" mr={1}>
                  <li style={{ marginBottom: desktop ? "5px" : 0 }}>
                    <Link
                      to="/"
                      className="footerFontSize hover"
                      style={{ color: grey[800] }}
                    >
                      {lan === 0 ? "Acasă" : "Home"}
                    </Link>
                  </li>
                  <li style={{ marginBottom: desktop ? "5px" : 0 }}>
                    <Link
                      to="/about"
                      className="footerFontSize hover"
                      style={{ color: grey[800] }}
                    >
                      {lan === 0 ? "Despre" : "About"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services"
                      className="footerFontSize hover"
                      style={{ color: grey[800] }}
                    >
                      {lan === 0 ? "Servicii" : "Services"}
                    </Link>
                  </li>
                </Box>
                <Box display="block">
                  <li style={{ marginBottom: desktop ? "5px" : 0 }}>
                    <Link
                      to="/contact"
                      className="footerFontSize hover"
                      style={{ color: grey[800] }}
                    >
                      Contact
                    </Link>
                  </li>
                  <li style={{ marginBottom: desktop ? "5px" : 0 }}>
                    <Link
                      to="/album"
                      className="footerFontSize hover"
                      style={{ color: grey[800] }}
                    >
                      {lan === 0 ? "Imagini" : "Images"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/documents"
                      className="footerFontSize hover"
                      style={{ color: grey[800] }}
                    >
                      {lan === 0 ? "Atestări" : "Certificates"}
                    </Link>
                  </li>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item width={gridWidth}>
            <Box>
              <Typography
                variant="h5"
                className={"footerHeader"}
                sx={{
                  fontFamily: "Anton",
                  color: "#0d0d3b",
                  mb: 2.5,
                }}
              >
                {lan === 0 ? "Cum ne găsești?" : "Find Us"}
              </Typography>
              <Typography
                className="footerFontSize"
                style={{ color: grey[800] }}
              >
                Șag, str. CIII, nr.17
              </Typography>
              <Typography
                className="footerFontSize"
                style={{ color: grey[800] }}
              >
                {lan === 0 ? "Telefon" : "Telephone"}: 0256 223367
              </Typography>
              <Typography
                className="footerFontSize"
                style={{ color: grey[800] }}
              >
                E-mail: office@mdelectric.ro
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="copyright">
        <p style={{ color: grey[100] }} className="footerFontSize">
          Copyright ©2022 All rights reserved | S.C. M.D. Electric S.R.L.
        </p>
      </Box>
    </Box>
  );
};
