import { alpha, Card, Container, Grid, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { Presentation } from "../components/Presentation.jsx";
import "../index.css";
import { useContext } from "react";
import { AppContext } from "../context/appContext.js";
import { Footer } from "../components/Footer.jsx";
import { Header } from "../components/Header.jsx";
import { Link } from "react-router-dom";

const paragraphsRO = [
  `S.C. M.D. ELECTRIC S.R.L. a fost înființată în Lugoj în anul 2006, fiind înregistrată la O.R.C. sub nr. J35/2813/08.09.2006, având codul unic de înregistrare 19002568. Sediul actual al societății se află în Șag, str. CIII, nr. 17, jud. Timiș, România.`,
  `M.D. ELECTRIC efectuează lucrări de instalații electrice care respectă standardele de calitate mediu-impuse la nivel internațional, având o dotare tehnică bogată și o mulțime de utilaje specifice domeniului de activitate. Drept urmare, obține în anii 2008, 2009 și 2010, locul I în topul firmelor din municipiul Lugoj.`,
  `Principalele domenii de activitate sunt:`,
  `• Lucrări de instalații electrice`,
  `• Activități de arhitectură, inginerie și servicii de consultanță tehnică legate de acestea, inclusiv elaborarea proiectelor referitoare la instalațiile electrice`,
  `Societatea a obținut de la Autoritatea Națională de Reglementare în domeniul Energiei (A.N.R.E.) următoarele `,
  `• C1B, C2B - pentru proiectare de linii electrice, aeriene sau subterane, cu tensiuni nominale de 0,4 kV + 110 kV, posturi de transformare cu tensiunea nominală superioară de cel mult 20 kV, stații de medie tensiune și de 110 kV, precum și partea electrică de medie tensiune a stațiilor cu tensiuni nominale mai mari sau egale cu 110 kV`,
  `• A3 - pentru încercări de echipamente și instalații electrice în vederea certificării conformității tehnice a centralelor electrice în raport cu normele tehnice aplicabile `,
  `S.C. M.D. ELECTRIC S.R.L. a obținut de la A.N.R.S.C. Licența pentru Serviciul de iluminat public - clasa 3, care ne oferă posibilitatea de a încheia contracte de mentenanță și gestiune pentru iluminat public cu unitățile administrativ teritoriale din zona de Vest.`,
];

const paragraphsEN = [
  `S.C. M.D. ELECTRIC S.R.L. was established in Lugoj, Romania, in the year 2006. It is registered at the O.R.C. under no. J35/2813/08.09.2006, having the unique registration code 19002568. The company is currently based in Șag, street CIII, no. 17, Timiș county, Romania.`,
  `M.D. ELECTRIC carries out electrical installations works that comply with the average quality standards imposed at the international level, having a lot of performant equipment specific to the field of activity. In the years 2008, 2009 and 2010, it obtained the first place in the top of companies in Lugoj.`,
  `The main areas of activity are:`,
  `• Electrical installations projects`,
  `• Architectural activities, engineering and technical consultancy related thereto, including the development of projects relating to electrical installations`,
  `The company obtained from the National Energy Regulatory Authority the following `,
  `• C1B, C2B - for the design of electrical lines, overhead or underground, with nominal voltages of 0.4 kV + 110 kV, transformer stations with a higher nominal voltage of no more than 20 kV, medium voltage stations and 110 kV stations, as well as the medium voltage electrical part of stations with nominal voltages greater than or equal to 110 kV `,
  `• A3 - for tests of electrical equipment and installations in order to certify the technical conformity of power plants in relation to the applicable technical norms `,
  `S.C. M.D. ELECTRIC S.R.L. obtained from A.N.R.S.C. the License for Public Lighting Service - class 3, which gives us the opportunity to conclude public lighting maintenance and management contracts with the administrative units in the Western area.`,
];

export const About = () => {
  const { lan } = useContext(AppContext);
  const { desktop } = useContext(AppContext);

  return (
    <section className="backsectionabout">
      <Header />
      <Container
        sx={{
          width: desktop ? "70%" : "95%",
          zIndex: 9,
          mt: desktop ? 22 : 5,
          mb: 7,
        }}
      >
        <Grid container spacing={2} display="block">
          <Presentation
            title={
              lan === 0
                ? "Cine suntem și cu ce ne ocupăm?"
                : "Who are we and what do we do?"
            }
            desc={
              lan === 0
                ? "Pentru mai multe informații ne puteți contacta prin numărul de telefon sau adresa de e-mail, afișate în secțiunea "
                : "For more details please contact us through the phone number or e-mail address, which are available within the following section: "
            }
            desc2={
              lan === 0
                ? " Prețurile serviciilor oferite de noi diferă în funcție de furnizori."
                : "The prices may differ depending on our suppliers."
            }
          />
          <Grid item>
            <Card
              sx={{
                boxShadow: "none",
                pt: 4,
                pl: 4,
                pr: 4,
                pb: 2,
                backgroundColor: alpha("#0d0d3b", 0.1),
                ":hover": {
                  cursor: "text",
                },
              }}
            >
              <Typography
                className={"fontSize"}
                variant="body1"
                color={"#0d0d3b"}
                mb={1}
              >
                {lan === 0 ? paragraphsRO[0] : paragraphsEN[0]}
              </Typography>
              <Typography
                className={"fontSize"}
                variant="body1"
                color={"#0d0d3b"}
                mb={1}
              >
                {lan === 0 ? paragraphsRO[1] : paragraphsEN[1]}
              </Typography>
              <Typography
                className={"fontSize"}
                variant="body1"
                color={"#0d0d3b"}
                mb={1}
              >
                {lan === 0 ? paragraphsRO[2] : paragraphsEN[2]}
              </Typography>
              <Typography
                className={"fontSize"}
                variant="body1"
                color={"#0d0d3b"}
                ml={4}
              >
                {lan === 0 ? paragraphsRO[3] : paragraphsEN[3]}
              </Typography>
              <Typography
                className={"fontSize"}
                variant="body1"
                color={"#0d0d3b"}
                mb={1}
                ml={4}
              >
                {lan === 0 ? paragraphsRO[4] : paragraphsEN[4]}
              </Typography>
              <Typography
                className={"fontSize"}
                variant="body1"
                color={"#0d0d3b"}
                mb={1}
              >
                {lan === 0 ? paragraphsRO[5] : paragraphsEN[5]}{" "}
                <Link
                  to="/documents"
                  className="fontSize hover"
                  fontWeight={500}
                  style={{
                    color: red[900],
                  }}
                >
                  {lan === 0 ? "atestate" : "certificates"}
                </Link>
                :
              </Typography>
              <Typography
                className={"fontSize"}
                variant="body1"
                color={"#0d0d3b"}
                ml={4}
              >
                {lan === 0 ? paragraphsRO[6] : paragraphsEN[6]}
              </Typography>
              <Typography
                className={"fontSize"}
                variant="body1"
                color={"#0d0d3b"}
                mb={1}
                ml={4}
              >
                {lan === 0 ? paragraphsRO[7] : paragraphsEN[7]}
              </Typography>
              <Typography
                className={"fontSize"}
                variant="body1"
                color={"#0d0d3b"}
              >
                {lan === 0 ? paragraphsRO[8] : paragraphsEN[8]}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <br />
      <Footer />
    </section>
  );
};
