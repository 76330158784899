import { Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/appContext.js";

export const Presentation = ({ title, desc, desc2 }) => {
  const { tabletBig } = useContext(AppContext);
  return (
    <Box
      sx={{
        textAlign: "center",
        mb: 2,
        marginTop: tabletBig ? "4cm" : 0,
        display: "block",
      }}
    >
      <Typography
        variant="h3"
        mb={1}
        fontFamily={"Anton"}
        color={"#0d0d3b"}
        fontWeight={400}
        className="headerSize"
      >
        {title}
      </Typography>
      {desc !== "" ? (
        <Typography variant="body2" color={"#0d0d3b"} className="descSize">
          {desc}
          <Link
            to="/contact"
            className="fontSize hover"
            fontWeight={500}
            style={{
              color: red[900],
            }}
          >
            Contact
          </Link>
          .
        </Typography>
      ) : (
        <Typography variant="body2" className="descSize" color={"#0d0d3b"}>
          S.C. M.D. Electric S.R.L
        </Typography>
      )}
      {desc2 !== "" && (
        <Typography variant="body2" className="descSize" color={"#0d0d3b"}>
          {desc2}
        </Typography>
      )}
    </Box>
  );
};
