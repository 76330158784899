import { Box, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import "../index.css";
import { useContext } from "react";
import { AppContext } from "../context/appContext.js";
import { Footer } from "../components/Footer.jsx";
import { Header } from "../components/Header.jsx";
import { Link } from "react-router-dom";

export const Home = () => {
  const { lan } = useContext(AppContext);

  return (
    <section className="backsectionhome">
      <section className="homeSection">
        <Header />
        <Box sx={{ display: "flex" }}>
          <Box className="emptyLeftSide"></Box>
          <Box className="homeContainer" sx={{ mb: 5 }}>
            <Typography
              className="homeHeaderSize"
              fontFamily={"Anton"}
              sx={{
                color: "#0d0d3b",
                fontWeight: 400,
                ml: lan === 1 ? -0.5 : -0.1,
              }}
            >
              {lan === 0 ? "Bine ai venit" : "Welcome"}
            </Typography>
            <Typography
              className="homeFontSize"
              variant="h3"
              sx={{
                color: "#0d0d3b",
                fontWeight: 400,
              }}
            >
              {lan === 0
                ? "M.D. Electric reprezintă soluția ideală pentru lucrări de instalații electrice civile și industriale"
                : "M.D. Electric represents the ideal solution for civil and industrial electrical installations projects "}
            </Typography>
            <br />
            <Link
              className="homelink"
              to="/services"
              style={{ textDecorationColor: red[900] }}
            >
              <Typography className="homelink" variant="h4" color={red[900]}>
                {lan === 0
                  ? "  Click aici pentru a vedea serviciile noastre"
                  : "Click here to view our services"}
              </Typography>
            </Link>
          </Box>
        </Box>
      </section>
      <Footer />
    </section>
  );
};
