import { Box, Button, MenuItem, Popover, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Stack } from "@mui/system";
import { grey, indigo } from "@mui/material/colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useContext } from "react";
import { AppContext } from "../context/appContext.js";
import ROFlag from "../common/RO_flag.png";
import ENFlag from "../common/EN_Flag.jpg";
import PublicIcon from "@mui/icons-material/Public";

const LANGS = [
  {
    value: "Traducere pagină",
    index: 0,
    label: "Română",
    icon: ROFlag,
  },
  {
    value: "Translate page",
    index: 1,
    label: "English",
    icon: ENFlag,
  },
];

export const LanguagePopover = ({ fontColor, inFooter, size }) => {
  const anchorRef = useRef(null);
  const { lan, setLan } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        variant="text"
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          ml: inFooter === true ? -0.9 : 0,
          "&:hover": {
            color: indigo[200],
          },
        }}
      >
        {!inFooter && (
          <PublicIcon
            sx={{
              color: fontColor,
              pb: 0.5,
              mr: 2,
            }}
          />
        )}

        <Typography
          variant="body2"
          className={inFooter ? "footerFontSize" : ""}
          color={fontColor}
          sx={{ fontSize: size }}
        >
          {LANGS[lan].label}
        </Typography>
        <ArrowDropDownIcon style={{ color: fontColor, marginLeft: "-0.1cm" }} />
      </Button>

      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            overflow: "hidden",
            backgroundColor: inFooter ? grey[200] : "#ffffff",
          },
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: 180,
          mt: 1,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              sx={{ color: fontColor }}
              key={option.value}
              selected={option.value === LANGS[lan].value}
              onClick={() => {
                setLan(option.index);
                handleClose();
              }}
            >
              <Box
                component="img"
                src={option.icon}
                sx={{ width: 28, mr: 1 }}
              />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
};
