import { alpha, Box, Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "../index.css";
import { grey } from "@mui/material/colors";
import { CardItem } from "../components/CardItem.jsx";
import { useContext } from "react";
import Lightbox from "react-image-lightbox";
import { AppContext } from "../context/appContext.js";
import { Footer } from "../components/Footer.jsx";
import { Header } from "../components/Header.jsx";
import { Loading } from "../components/Loading.jsx";

require.context("./album", false, /\.(png|jpe?g|svg|webp)$/);

export const Album = () => {
  const { lan, desktop, tabletBig, images } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const [imagesScroll, setImagesScroll] = useState([]);
  const [lastImg, setLastImg] = useState(0);

  const firstImages = (arr) => {
    let aux = [];
    for (let i = 0; i < 8; i++) {
      if (i <= images.length - 8) aux.push(arr[i]);
    }
    return aux;
  };

  const parseImages = (arr) => {
    let aux = [];
    for (let i = lastImg; i < lastImg + 4; i++) {
      if (i <= images.length - 4) aux.push(arr[i]);
    }
    return aux;
  };

  const loadFirst = () => {
    const aux = firstImages(images);
    setImagesScroll(imagesScroll.concat(aux));
    setLastImg(imagesScroll.length + 1);
  };

  const [howManyYouSee, setHowManyYouSee] = useState(8);
  const loadMore = () => {
    if (howManyYouSee <= images.length - 4) {
      const aux = parseImages(images);
      setImagesScroll(imagesScroll.concat(aux));
      setLastImg(imagesScroll.length + 1);
      setHowManyYouSee(howManyYouSee + 4);
    }
  };

  useEffect(() => {
    loadFirst();
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const [imgIndex, setImgIndex] = useState(0);

  useEffect(() => {
    setImgIndex(images.indexOf(image));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <section className={"backsectionalbum"}>
      <Header />
      <Container
        sx={{
          width: desktop ? "70%" : "95%",
          zIndex: 9,
          mt: desktop || tabletBig ? 19 : 5,
          mb: tabletBig ? 15 : 5,
          height: loading === true ? 650 : "",
        }}
      >
        {loading === true ? (
          <Loading />
        ) : (
          <>
            <Box sx={{ textAlign: "center", mb: 2 }}>
              <Typography
                variant="body2"
                className="descsize"
                color={"#0d0d3b"}
              >
                {lan === 0
                  ? "Toate fotografiile reprezintă lucrări realizate exclusiv de S.C. M.D. Electric S.R.L."
                  : "All photos represent projects made exclusively by S.C. M.D. Electric S.R.L."}
              </Typography>
            </Box>

            <Grid container spacing={2}>
              {imagesScroll.map((card, i) => (
                <CardItem
                  key={i}
                  setOpen={setOpen}
                  setImage={setImage}
                  card={card}
                  isDoc={false}
                />
              ))}
              {open && (
                <Lightbox
                  mainSrc={images[imgIndex] ?? images[0]}
                  nextSrc={images[(imgIndex + 1) % images.length]}
                  prevSrc={
                    images[(imgIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => setOpen(false)}
                  onMovePrevRequest={() =>
                    setImgIndex((imgIndex + images.length - 1) % images.length)
                  }
                  onMoveNextRequest={() =>
                    setImgIndex((imgIndex + 1) % images.length)
                  }
                />
              )}
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 4,
                width: "100%",
                background: alpha(grey[100], 0.6),
              }}
            >
              <Button
                onClick={() => loadMore()}
                style={{ color: "#0d0d3b", width: "100%" }}
              >
                {lan === 0
                  ? `Vezi mai mult (${howManyYouSee} din ${images.length})`
                  : `Load More (${howManyYouSee} out of ${images.length})`}
              </Button>
            </Box>
          </>
        )}
      </Container>
      <Footer />
    </section>
  );
};
