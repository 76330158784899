import { Box, Card, Grid, Typography } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { alpha, Container } from "@mui/system";
import { Presentation } from "../components/Presentation.jsx";
import "../index.css";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/appContext.js";
import { Footer } from "../components/Footer.jsx";
import { Header } from "../components/Header.jsx";
import { Link } from "react-router-dom";

const contactdetails = [
  {
    keyRO: "Adresă",
    keyEN: "Address",
    value: `Șag, str. CIII, nr.17`,
  },
  {
    keyRO: "Adresă poștală",
    keyEN: "Mailing address",
    value: `  Timișoara,
    Calea Stan Vidrighin, nr. 20`,
  },
  { keyRO: "Telefon", keyEN: "Telephone", value: `0256 223367` },
  { keyRO: "Fax", keyEN: "Fax", value: `0256 401048` },
  { keyRO: "E-mail", keyEN: "E-mail", value: `office@mdelectric.ro` },
  {
    keyRO: "E-mail (secundar)",
    keyEN: "E-mail (secondary)",
    value: `tehnic@mdelectric.ro`,
  },
];

export const Contact = () => {
  const { lan, desktop, phone, tabletBig, tabletVertical } =
    useContext(AppContext);

  const openApp = (i, detail) => {
    if (i === 4 || i === 5) {
      window.location.href = `mailto:${detail.value}`;
    }
    if (i === 2) {
      window.location.href = `tel:${detail.value}`;
    }
  };

  const [top, setTop] = useState(22);
  const setMarginTop = () => {
    if (desktop) setTop(10);
    else if (tabletBig) setTop(34);
    else if (tabletVertical) setTop(22);
    else setTop(5);
  };

  useEffect(() => {
    setMarginTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, tabletVertical, tabletBig]);
  return (
    <section className="backsectioncontact">
      <Header />
      <Container
        sx={{
          width: desktop ? "70%" : "95%",
          zIndex: 9,
          mt: desktop || tabletBig ? 22 : 7,
          mb: top,
        }}
      >
        <Grid container spacing={2} display="block">
          <Presentation
            title={lan === 0 ? "Contactează-ne" : "Contact Us"}
            desc={""}
          />

          {contactdetails.map((detail, i) => (
            <Grid key={i} item>
              <Card
                onClick={() => openApp(i, detail)}
                sx={{
                  boxShadow: "none",
                  pt: 2,
                  pl: 3,
                  pr: 3,
                  pb: 1,
                  backgroundColor: alpha("#0d0d3b", 0.1),
                  ":hover": {
                    cursor:
                      (phone && i === 2) || i === 4 || i === 5
                        ? "pointer"
                        : "text",
                    backgroundColor:
                      (phone && i === 2) || i === 4 || i === 5
                        ? alpha(red[700], 0.1)
                        : alpha(grey[100], 0.7),
                  },
                }}
              >
                <Box display={"flex"}>
                  <Box width={"40%"}>
                    <Link
                      className={"fontSize"}
                      fontWeight={500}
                      mr={5}
                      style={{
                        color: "#0d0d3b",
                        textDecoration: "none",
                      }}
                    >
                      {lan === 0 ? detail.keyRO : detail.keyEN}
                    </Link>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      className={"fontSize"}
                      color="#0d0d3b"
                      mb={1}
                    >
                      {detail.value}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </section>
  );
};
