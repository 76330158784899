import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";

import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { indigo } from "@mui/material/colors";

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "#0d0d3b",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function NavItem({ item, active }) {
  const isActiveRoot = active(item.path);

  const { title, path, icon } = item;

  return (
    <ListItemButton
      component={RouterLink}
      to={path}
      sx={{
        color: "#0d0d3b",
        fontWeight: "fontWeightMedium",
        bgcolor: isActiveRoot ? alpha(indigo[200], 0.4) : alpha(indigo[200], 0),
      }}
    >
      <ListItemIconStyle>{icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
    </ListItemButton>
  );
}

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
