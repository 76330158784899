import { Card, CardMedia, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { alpha, Box } from "@mui/system";
import { useContext } from "react";
import { AppContext } from "../context/appContext.js";

export const CardItem = ({ card, setImage, setOpen, isDoc }) => {
  const { desktop, tabletBig, lan } = useContext(AppContext);

  const getHeight = () => {
    if (isDoc) {
      if (desktop) return "35vh";
    } else {
      if (tabletBig) return "20vh";
      else return "30vh";
    }
  };

  const getView = () => {
    if (isDoc) {
      if (lan === 0) return "Vezi Documentul";
      else return "View Document";
    } else {
      if (lan === 0) return "Vezi Imaginea";
      else return "View Image";
    }
  };

  const view = getView();

  return (
    <Grid item xs={20} md={isDoc ? 6 : 3}>
      <Box sx={{ position: "relative" }}>
        {desktop && (
          <Box
            onClick={() => {
              setOpen(true);
              setImage(card);
            }}
            sx={{
              textAlign: "center",
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 9,
              cursor: "pointer",
              opacity: 0,
              "&:hover": {
                opacity: 1,
                background: alpha(grey[900], 0.7),
              },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                marginTop: "50%",
                fontSize: "24px",
                color: alpha(grey[100], 0.7),
              }}
            >
              {view}
            </Typography>
          </Box>
        )}
        <Card
          onClick={() => {
            if (!desktop) {
              setOpen(true);
              setImage(card);
            }
          }}
          sx={{
            zIndex: 5,
            backgroundColor: alpha(grey[200], 0),
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component={"img"}
            src={card}
            alt={card}
            sx={{
              height: getHeight,
              pt: "0%",
              zIndex: -1,
            }}
          />
        </Card>
      </Box>
    </Grid>
  );
};
