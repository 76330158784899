import { Box, Button } from "@mui/material";
import { Navbar } from "./Navbar.jsx";
import headerImg from "../common/logo2.svg";
import "../index.css";
import { useContext, useState } from "react";
import DashboardSidebar from "./Sidebar.jsx";
import MenuIcon from "@mui/icons-material/Menu";
import { AppContext } from "../context/appContext.js";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const { desktop } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <div className="block" style={{ position: "relative" }}>
      <Box sx={{ display: "flex" }}>
        {desktop === false && (
          <Box
            sx={{
              ml: "2%",
              mr: "2%",
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
              zIndex: 9,
            }}
          >
            <Button
              sx={{ color: "#0d0d3b" }}
              onClick={() => setOpenSidebar(true)}
            >
              <MenuIcon
                sx={{
                  color: "#0d0d3b",
                }}
              />
            </Button>
            <img
              src={headerImg}
              className="logostyle"
              alt={"Header"}
              onClick={() => navigate("/")}
            />
          </Box>
        )}
      </Box>
      {desktop === true ? (
        <Navbar />
      ) : (
        <DashboardSidebar
          isOpenSidebar={openSidebar}
          onCloseSidebar={() => setOpenSidebar(false)}
        />
      )}
    </div>
  );
};
