import { alpha, Button, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { grey, indigo } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../context/appContext.js";
import "../index.css";
import { LanguagePopover } from "./LanguagePopover.jsx";

export const Navbar = () => {
  const { lan } = useContext(AppContext);

  const [loc, setLoc] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setLoc(location.pathname);
  }, [location]);

  return (
    <AppBar
      position="absolute"
      elevation={0}
      sx={{
        background: alpha(grey[100], 0.5),
        width: "96%",
        ml: "2%",
        mr: "2%",
        top: "3vh",
      }}
    >
      <Toolbar>
        <Button
          onClick={() => navigate("/")}
          sx={{
            color: "#0d0d3b",
            background: loc === "/" ? alpha(indigo[200], 0.4) : "none",
            mr: "1%",
          }}
        >
          {lan === 0 ? "Acasă" : "Home"}
        </Button>
        <Button
          onClick={() => navigate("/about")}
          sx={{
            color: "#0d0d3b",
            background: loc === "/about" ? alpha(indigo[200], 0.4) : "none",
            mr: "1%",
          }}
        >
          {lan === 0 ? "Despre Noi" : "About Us"}
        </Button>
        <Button
          onClick={() => navigate("/services")}
          sx={{
            color: "#0d0d3b",
            background: loc === "/services" ? alpha(indigo[200], 0.4) : "none",
            mr: "1%",
          }}
        >
          {lan === 0 ? "Servicii" : "Services"}
        </Button>
        <Button
          onClick={() => navigate("/contact")}
          sx={{
            color: "#0d0d3b",
            background: loc === "/contact" ? alpha(indigo[200], 0.4) : "none",
            mr: "1%",
          }}
        >
          Contact
        </Button>
        <Button
          onClick={() => navigate("/album")}
          sx={{
            color: "#0d0d3b",
            background: loc === "/album" ? alpha(indigo[200], 0.4) : "none",
            mr: "1%",
          }}
        >
          {lan === 0 ? " Lucrări Realizate" : "Our Projects"}
        </Button>
        <Button
          onClick={() => navigate("/documents")}
          sx={{
            color: "#0d0d3b",
            background: loc === "/documents" ? alpha(indigo[200], 0.4) : "none",
          }}
        >
          {lan === 0 ? "Atestări" : "Certificates"}
        </Button>
        <Box
          sx={{
            position: "absolute",
            right: "2.5%",
            display: "flex",
          }}
        >
          <LanguagePopover
            fontColor={"#0d0d3b"}
            size={"16px"}
            inFooter={false}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
